import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';


function HomePage() {
    return (
        <Container className="home">
            <Row>
                <Col className="home-col text-center">
                    <h5 className="home-col-h5">AWS Cost and usage</h5>
                    <Row>
                        <Col>
                            <Alert key="light" variant="success">
                                DEV
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                TEST
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                PROD
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                CICD
                            </Alert>
                        </Col>
                    </Row>
                </Col>
                <Col className="home-col text-center">
                    <h5 className="home-col-h5">AWS Health</h5>
                    <Row>
                        <Col>
                            <Alert key="light" variant="success">
                                DEV
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                TEST
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                PROD
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                CICD
                            </Alert>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="home-col text-center">
                    <h5 className="home-col-h5">Trusted Advisor summary</h5>
                    <Row>
                        <Col>
                            <Alert key="light" variant="success">
                                DEV
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                TEST
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                PROD
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                CICD
                            </Alert>
                        </Col>
                    </Row>
                </Col>
                <Col className="home-col text-center">
                    <h5 className="home-col-h5">Security Hub summary</h5>
                    <Row>
                        <Col>
                            <Alert key="light" variant="success">
                                DEV
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                TEST
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                PROD
                            </Alert>
                        </Col>
                        <Col>
                            <Alert key="light" variant="success">
                                CICD
                            </Alert>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="home-col text-center">
                    <h5 className="home-col-h5">DataDog summary</h5>
                </Col>
            </Row>
        </Container>
    )
}

export default HomePage;