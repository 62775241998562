import React from 'react';
import './Error.css';
import Game from './easteregg'

const Error404 = () => {
  return (
    <div className="error-page">
      <h1>Look like you're lost</h1>
      <p>The page you are looking for does not exist or has been moved.</p>
      <Game />
    </div>
  );
};

export default Error404;