import React from 'react';

import { Container, Row, Col, ListGroup, Alert } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

function AccessDenied() {

    return (
        <>
            <Container className="d-flex flex-column align-items-center" style={{ marginTop: '75px' }}>
                <Row>
                    <Col>
                        <Alert variant="danger">
                            <Alert.Heading>Access Denied!</Alert.Heading>
                            <p>You do not have permissions to access this page. Please contact your administrator if you believe this is an error.</p>
                            <hr />
                            <h6>Possible reasons for this issue:</h6>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li">You are not part of the required group to access this content.</ListGroup.Item>
                                <ListGroup.Item as="li">There may be an issue with your account permissions.</ListGroup.Item>
                            </ListGroup>
                        </Alert>
                    </Col>
                </Row>
            </Container >
        </>
    );
};

export default AccessDenied;