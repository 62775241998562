import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';

import { Amplify } from 'aws-amplify';
import { signOut } from "aws-amplify/auth";

import awsExports from './aws-exports'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import SiteNav from './Components/common/SiteNav';
import SiteFooter from './Components/common/SiteFooter';
import HomePage from './Components/home/HomePage';
import ReleasesPage from './Components/releases/ReleasesPage';
import MonitoringPage from './Components/monitoring/MonitoringPage';
import SecurityHubPage from './Components/securityHub/SecurityHubPage';
import TrustedAdvisorPage from './Components/trustedAdvisor/TrustedAdvisorPage';
import ContactsPage from './Components/contacts/ContactsPage';
import AccessCheck from './Components/auth/AccesCheck';
import AccessDenied from './Components/auth/AccessDenied';
import Login from './Components/common/Login';

import Error404 from './Components/errors/Error404';
import ErrorBoundary from './Components/errors/ErrorBoundary';


Amplify.configure(awsExports);

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={
            <>
              <SiteNav logOut={signOut} />
              <div className="content">
                <AccessCheck signOut={signOut}><HomePage /></AccessCheck>
              </div>
              <SiteFooter />
            </>
          } />
          <Route path="/releases" element={
            <>
              <SiteNav logOut={signOut} />
              <div className="content">
                <AccessCheck signOut={signOut}><ReleasesPage /></AccessCheck>
              </div>
              <SiteFooter />
            </>
          } />
          <Route path="/monitoring" element={
            <>
              <SiteNav logOut={signOut} />
              <div className="content">
                <AccessCheck signOut={signOut}><MonitoringPage /></AccessCheck>
              </div>
              <SiteFooter />
            </>
          } />
          <Route path="/security-hub" element={
            <>
              <SiteNav logOut={signOut} />
              <div className="content">
                <AccessCheck signOut={signOut}><SecurityHubPage /></AccessCheck>
              </div>
              <SiteFooter />
            </>} />
          <Route path="/trusted-advisor" element={
            <>
              <SiteNav logOut={signOut} />
              <div className="content">
                <AccessCheck signOut={signOut}><TrustedAdvisorPage /></AccessCheck>
              </div>
              <SiteFooter />
            </>} />
          <Route path="/contacts" element={
            <>
              <SiteNav logOut={signOut} />
              <div className="content">
                <AccessCheck signOut={signOut}><ContactsPage /></AccessCheck>
              </div>
              <SiteFooter />
            </>} />
          <Route path="/login" element={
            <AccessCheck>
              <Login />
            </AccessCheck>
          } />
          <Route path="/access_denied" element={
            <>
              <SiteNav logOut={signOut} />
              <div className="content">
                <AccessCheck>
                  <AccessDenied />
                </AccessCheck>
              </div>
              <SiteFooter />
            </>
          } />
          <Route path="*" element={<AccessCheck signOut={signOut}><Error404 /></AccessCheck>} />
        </Routes>
      </ErrorBoundary >
    </Router >
  )
}

export default App;
