import PropTypes from 'prop-types';
import useAuth from '../../Hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from '../common/Loading';

const cognitoAdminGroup = process.env.REACT_APP_COGNITO_ADMIN_GROUP;
const cognitoReadOnlyGroup = process.env.REACT_APP_COGNITO_READONLY_GROUP;

const hasRequiredAccess = (groups) => {
    return [cognitoAdminGroup, cognitoReadOnlyGroup].some(group => groups.includes(group));
}

function AccessCheck({ children }) {
    const { tokens, loading } = useAuth();
    const location = useLocation();
    const pathname = location.pathname;

    if (loading) {
        return <Loading />
    }

    if (!tokens) {
        if (pathname === "/login") {
            return children;
        }
        return <Navigate to="/login" replace />;
    }

    const { accessToken: { payload: { "cognito:groups": groups = [] } = {} } = {} } = tokens || {};
    const hasAccess = hasRequiredAccess(groups);

    if (!hasAccess) {
        if (pathname === "/access_denied") {
            return children;
        }
        return <Navigate to="/access_denied" replace />;
    }

    if (pathname === "/login" || pathname === "/access_denied") {
        return <Navigate to="/" replace />;
    }

    return children;
}

AccessCheck.propTypes = {
    children: PropTypes.node.isRequired
};

export default AccessCheck;