import { useEffect, useState } from 'react';
import { fetchAuthSession } from "aws-amplify/auth";

import { Hub } from 'aws-amplify/utils';

const getTokens = async () => {
    try {
        const { tokens } = await fetchAuthSession();
        return tokens;
    }
    catch (_a) {
        return [];
    }
};

const useAuth = () => {
    const [tokens, setTokens] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const updateTokens = async () => {
            const tokens = await getTokens();
            setTokens(tokens);
            setLoading(false);
        };
        const unsibscribe = Hub.listen("auth", updateTokens);
        updateTokens();
        return unsibscribe();
    }, []);
    return { tokens, loading };
};

export default useAuth;
export { getTokens };
