import { Oval } from 'react-loader-spinner';

const Loading = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Oval
                height={80}
                width={80}
                color="#0b88d1"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#0b88d1"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    )
}

export default Loading;