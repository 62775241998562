const awsConfig = {
    aws_project_region: 'eu-central-1',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['email', 'openid'],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
        responseType: 'code'
    }
};

export default awsConfig;
