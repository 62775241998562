import React from 'react';
import './SiteFooter.css';

const SiteFooter = () => {
  return (
    <footer className="sitefooter">
      <div className="sitefooter-content">
        <nav className="sitefooter-nav">
          <ul>
            <li><span>&copy; 2024</span></li>
          </ul>
          <ul>
            <li><a href="https://confluence.tuigroup.com/display/ONEPF/Finance+Service" target="_blank" rel="noreferrer">Confluence</a></li>
            <li><a href="https://source.tui/wr/cloud/trips/finance" target="_blank" rel="noreferrer">Gitlab</a></li>
          </ul>
          <ul>
            <li><a href="https://youtu.be/dQw4w9WgXcQ?si=fKcV6dHjKHgX29wS" target="_blank" rel="noreferrer">Click here</a></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default SiteFooter;
