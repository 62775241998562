import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function SiteNav({ logOut }) {
    const handleLogout = () => {
        logOut();
    }
    return (
        <header>
            <Navbar collapseOnSelect expand="md" className="bg-dark" variant="dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="/img/logo.png"
                            height="50"
                            className="d-inline-block align-center"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" >
                        <Nav className="me-auto">
                            <Nav.Link href="/releases">Releases</Nav.Link>
                            <Nav.Link href="/monitoring">Monitoring</Nav.Link>
                            <Nav.Link href="/security-hub">Security Hub</Nav.Link>
                            <Nav.Link href="/trusted-advisor">Trusted Advisor</Nav.Link>
                            <Nav.Link href="/contacts">Contacts</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
        </header>
    )
}

export default SiteNav;