import React from "react";

import { Container, Table } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/contacts-page.css'

const contacts = [
    { name: "DevOps Finance Team", email: "finance.devops@tui.be" },
    { name: "Leonid Pogozhev", email: "leonid.pogozhev@tui.co.uk", title: "Lead DevOps" },
    { name: "Oleksii Hrebenik", email: "oleksii.hrebenik@tui.co.uk", title: "DevOps" },
    { name: "Valerii Shaulov", email: "valerii.shaulov@tui.co.uk", title: "DevOps" },
];

function Contact({ index, name, email, title }) {
    return (
        <tr>
            <td>{index}</td>
            <td>{name}</td>
            <td>{email}</td>
            <td>{title}</td>
        </tr>
    )
}


function Contacts() {
    return (
        <>
            <Container className="text-center">
                <h2 className="table-title">Contact us</h2>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            contacts.map((contact, index) => (
                                <Contact key={index} index={index + 1} name={contact.name} email={contact.email} title={contact.title} />
                            ))
                        }
                    </tbody>
                </Table>
            </Container>
        </>
    )
}

export default Contacts;